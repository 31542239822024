import React, { useEffect, useState, useRef } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import List from '../../shared/List/List';
import TicketListItem from './TicketListItem/TicketListItem';
import TicketDetail from './TicketDetail/TicketDetail';
import { useSelector, useDispatch } from 'react-redux';
import Search from '../../shared/Search/Search';
import {
    reset,
    pageScroll,
    search,
    setLoading,
    setFilters,
    selectElement,
    refresh,
    goTo,
} from '../../controllers/TicketsController/TicketsActions';
import {
    TicketFilters,
    ticketsToQueryString,
} from '../../controllers/TicketsController/TicketsReducer';
import { RootState } from '../../utils/_store';
import Filters from './TicketFilters/TicketFilters';
import { Ticket } from '../../@Types/TicketTypes/Ticket';
import { match, RouteComponentProps, withRouter } from 'react-router-dom';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import CreateTicket from './CreateTicket/CreateTicket';
import Dialog from '../../shared/Dialog/Dialog';
import RefreshButton from '../../shared/RefreshButton/RefreshButton';
import { checkProjPerm } from '../../utils/PermissionsFunctions';
import { ProjectPermissions } from '../../constants/Permissions';
import { RouterProps } from '../../Router';
import Prodesa360Button from '../../custom/Prodesa/Prodesa360Button';
import CreateOutboundTicket from './CreateOutboundTicket/CreateOutboundTicket';
import { TicketFilterNotifications } from './TicketFilterNotifications/TicketFilterNotifications';
import { useCurrentProject } from '../../hooks';
interface IdTicketRouteParam {
    /** Current Ticket */
    idTicket?: string;
    /** The id of the currentProject */
    idProject: string;
}
interface TicketsPageProps extends RouteComponentProps {
    match: match<IdTicketRouteParam>;
}

function TicketsPage({
    history,
    location,
    match: {
        params: { idTicket, idProject },
    },
    mobile,
}: TicketsPageProps & RouterProps): JSX.Element {
    const dispatch = useDispatch();
    const [today] = useState(new Date()); //Todo esta fecha se deberia recalcular en 1 hr?
    const pageInfo = useSelector((state: RootState) => state.ticketsPage);
    const listRef = useRef<HTMLDivElement>(null);
    const user = useSelector((state: RootState) => state.site.user);
    const idOrganization = useSelector(
        (state: RootState) => state.site.organization?.idOrganization
    );
    const project = useCurrentProject();
    const [createDialog, setCreateDialog] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        if (listRef.current) listRef.current.scrollTop = 0;
        dispatch(reset({ queryString: location.search, idTicket }));
        return (): void => {
            dispatch(selectElement(undefined));
        };
    }, [project]);

    /** Function called when the user goes back o foward with navbar btns. */
    function handleGoBack(): void {
        const paths = window.location.pathname.split('/');
        if (paths[2] !== 'tickets') return;
        //If same project
        if (paths.length > 2 && paths[1] == idProject) {
            dispatch(
                goTo({
                    idTicket: paths[3],
                    queryString: window.location.search,
                })
            );
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const forced = !!urlParams.get('forced');
        if (forced) {
            urlParams.delete('forced');
            const searchString = urlParams.toString();
            history.replace({
                search: searchString ? `?${searchString}` : '',
            });
        }
        if (firstTime) {
            setFirstTime(false);
            return;
        }
        if (
            idTicket &&
            (pageInfo.selectedElement === undefined ||
                pageInfo.order[pageInfo.selectedElement] !== idTicket)
        ) {
            dispatch(
                goTo({
                    idTicket,
                    queryString: window.location.search,
                    forced,
                })
            );
        }
    }, [idTicket]);

    useEffect(() => {
        /** On browser go back check if ticket id changes */
        window.addEventListener('popstate', handleGoBack);
        if (!mobile && history.location.pathname.includes('/activity')) {
            const url =
                history.location.pathname.replace('/activity', '') +
                history.location.search;
            history.replace(url);
        }

        return (): void => {
            window.removeEventListener('popstate', handleGoBack);
        };
    }, [idProject]);

    const updateUrl = (
        filters: TicketFilters,
        orderBy: string,
        search: string | undefined,
        type: 'FILTER' | 'SEARCH' | 'ORDER'
    ): void => {
        let url = `/${idProject}/tickets`;
        const filtersUrl = ticketsToQueryString(filters, orderBy, search, true);
        const urlPayload = {
            pathname: url,
            search: filtersUrl,
            state: { changeType: type },
        };
        if ((history.location?.state as any)?.changeType) {
            history.replace(urlPayload);
        } else {
            history.push(urlPayload);
        }
    };

    return (
        <React.Fragment>
            {createDialog && (
                <Dialog
                    maxWidth="100vw"
                    open={createDialog}
                    disableEnforceFocus
                    onClose={(): void => setCreateDialog(false)}
                >
                    <CreateTicket
                        close={(): void => setCreateDialog(false)}
                        handleConfirmed={(): void => {
                            dispatch(refresh());
                            if (listRef.current) listRef.current.scrollTop = 0;
                        }}
                    />
                </Dialog>
            )}
            <div className="standard-layout">
                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1280}
                    loading={pageInfo.loading}
                    detailOnFirstLoad={idTicket !== undefined}
                    listHeaderInfo={(): JSX.Element => (
                        <React.Fragment>
                            <PageTitle
                                title={'Mis Casos'}
                                route={project?.name + ' / Mis Casos'}
                            />
                            <div className="filters-container flexbox">
                                {checkProjPerm(
                                    user,
                                    idProject,
                                    ProjectPermissions.CREATE_INTERNAL
                                ) && (
                                    <RoundedButton
                                        data-testid="create_ticket_button"
                                        text="Nuevo"
                                        backgroundColor={'var(--secondary)'}
                                        color={'white'}
                                        onClick={(): void => {
                                            setCreateDialog(true);
                                        }}
                                    />
                                )}
                                {idOrganization === 'arquimedes' && (
                                    <CreateOutboundTicket
                                        handleConfirmed={(): void => {
                                            dispatch(refresh());
                                            if (listRef.current)
                                                listRef.current.scrollTop = 0;
                                        }}
                                    />
                                )}
                                <Filters
                                    handleFilter={(
                                        filters: TicketFilters
                                    ): void => {
                                        if (listRef.current)
                                            listRef.current.scrollTop = 0;
                                        dispatch(setFilters(filters));
                                        updateUrl(
                                            filters,
                                            pageInfo.orderBy,
                                            pageInfo.search,
                                            'FILTER'
                                        );
                                    }}
                                    mobile={mobile}
                                />
                                {/* <OrderBy
                                    // currentOrder={
                                    //     pageInfo.search
                                    //         ? undefined
                                    //         : pageInfo.orderBy
                                    // }
                                    handleOrder={(order: string): void => {
                                        listRef.current.scrollTop = 0;
                                        dispatch(orderBy(order));
                                        updateUrl(
                                            pageInfo.filters,
                                            order,
                                            pageInfo.search,
                                            'ORDER'
                                        );
                                    }}
                                /> */}
                                <RefreshButton
                                    onClick={(): void => {
                                        dispatch(refresh());
                                        if (listRef.current)
                                            listRef.current.scrollTop = 0;
                                    }}
                                />
                                <TicketFilterNotifications
                                    onClick={(filters): void => {
                                        if (listRef.current)
                                            listRef.current.scrollTop = 0;
                                        dispatch(setFilters(filters));
                                        updateUrl(
                                            filters,
                                            pageInfo.orderBy,
                                            pageInfo.search,
                                            'FILTER'
                                        );
                                    }}
                                />
                                {(idOrganization === 'arquimedes' ||
                                    idOrganization === 'prodesa' ||
                                    idOrganization === 'conaltura') && (
                                    <Prodesa360Button
                                        ticket={
                                            pageInfo.selectedElement !==
                                            undefined
                                                ? pageInfo.elements[
                                                      pageInfo.order[
                                                          pageInfo
                                                              .selectedElement
                                                      ]
                                                  ]
                                                : undefined
                                        }
                                    />
                                )}
                                <div className="search-container">
                                    <Search
                                        search={pageInfo.search}
                                        handleLoadingSearch={(
                                            loading: boolean
                                        ): void => {
                                            dispatch(setLoading(loading));
                                        }}
                                        handleSearch={(value: string): void => {
                                            if (listRef.current)
                                                listRef.current.scrollTop = 0;
                                            const pSearch =
                                                value === ''
                                                    ? undefined
                                                    : value;
                                            dispatch(search(pSearch));
                                            updateUrl(
                                                pageInfo.filters,
                                                pageInfo.orderBy,
                                                pSearch,
                                                'SEARCH'
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    selectElementLabel="Selecciona un Caso"
                    detailPaddingAndScroll={false}
                    selectedElement={
                        pageInfo.selectedElement !== undefined
                            ? pageInfo.elements[
                                  pageInfo.order[pageInfo.selectedElement]
                              ]
                            : undefined
                    }
                    handleSelectedElement={(element?: Ticket): void => {
                        const index = pageInfo.order.findIndex(
                            (idTicket) => idTicket === element?._id
                        );

                        if (!mobile || (mobile && element)) {
                            history.push({
                                pathname: `/${idProject}/tickets${
                                    element ? '/' + element._id : ''
                                }`,
                                search: history.location.search,
                                state: {
                                    prevPath: history.location.pathname,
                                },
                            });
                            dispatch(selectElement(index));
                        } else {
                            if (
                                (history.location?.state as any)?.prevPath ===
                                `/${idProject}/tickets`
                            ) {
                                history.goBack();
                            } else {
                                history.push(
                                    `/${idProject}/tickets` +
                                        history.location.search
                                );
                                dispatch(selectElement(index));
                            }
                        }
                    }}
                    controlledReturn
                >
                    <List
                        listId={'tickets-infiniteList'}
                        elements={pageInfo.order.map((id) => {
                            return pageInfo.elements[id];
                        })}
                        lastPage={pageInfo.noMoreElements}
                        listRef={listRef}
                        onPageScroll={(): void => {
                            dispatch(pageScroll());
                        }}
                        label="No tienes casos"
                    >
                        <TicketListItem
                            today={today}
                            showNotifications={true}
                        />
                    </List>
                    <TicketDetail mobile={mobile} />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default withRouter((props: any) => <TicketsPage {...props} />);
