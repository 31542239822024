import {
    FormActivity,
    Trigger,
} from '../../../../../@Types/ProcessTypes/Activity';
import React, { useMemo, useState } from 'react';
import styles from './FormActivity.module.css';
import InternalActivity from '../InternalActivity';
import ActivityTriggerTypes from '../../../../../constants/ActivityTypes/ActivityTriggerType';
import ConfirmDialog from '../../../../../shared/ConfirmDialog/ConfirmDialog';
import ActivitiesService from '../../../../../controllers/TicketsController/Activities/ActivitiesService';
import { Ticket } from '../../../../../@Types/@Types';
import { useDispatch } from 'react-redux';
import VanillaToasts from '../../../../../shared/Toast/Toast';
import SubmitBtns from '../SubmitBtns/SubmitBtns';
import { refreshCurrent } from '../../../../../controllers/TicketsController/TicketsActions';
import FormRenderer from '../../../../../shared/FormRenderer/FormRenderer';
import { FormCurrentTicketActivityValues } from '../../../../../@Types/TicketTypes/Ticket';
import FormDialog from '../../../../../shared/FormRenderer/FormDialog/FormDialog';
import { useAppSelector } from '../../../../../hooks';
import { ActivityTypes } from '../../../../../constants/ActivityTypes/ActivityTypes';
import { FormActivityAction } from '../../../../../@Types/ProcessTypes/ActivityAction';
export interface FormActivityProps {
    path: string;
    ticket: Ticket;
    activity: FormActivity;
    originalValues: FormCurrentTicketActivityValues;
}

function FormActivityComponent({
    path,
    ticket,
    activity,
    originalValues,
}: FormActivityProps): JSX.Element {
    const [state, setState] = useState<
        | {
              trigger: Trigger;
              values: Record<string, unknown>;
              showDialog: boolean;
              showConfirmation: boolean;
          }
        | undefined
    >();
    const [valuesCache, setValuesCache] =
        useState<FormCurrentTicketActivityValues>(
            originalValues ?? {
                triggers: {},
            }
        );
    const [loading, setLoading] = useState<null | string | undefined>();
    const dispatch = useDispatch();

    const ticketActivity = useMemo(() => {
        const ticketActivities = ticket.process?.activities[activity.id];
        const ticketActivity = ticketActivities?.find(
            (act) => act.path === path
        );
        if (ticketActivity?.type === ActivityTypes.FORM) return ticketActivity;
        return null;
    }, [ticket.process, activity]);

    const action = useAppSelector(
        (state) =>
            state.ticketActions.elements.find(
                (action) => action._id === ticketActivity?.idAction
            ) as FormActivityAction | undefined
    );

    const handleSend = async (
        trigger: Trigger = activity.defaultTrigger,
        values?: Record<string, any>
    ): Promise<void> => {
        setLoading(trigger.id);
        const isDefault = trigger.id === activity.defaultTrigger.id;
        let next: typeof state;
        let send = false;
        /** Si está en preview */
        if (!state) {
            if (isDefault) {
                const hasMoreSteps =
                    activity.previewSteps.length !==
                        trigger.form?.rootSteps.length ||
                    activity.previewSteps.find(
                        (idStep) => !trigger.form?.rootSteps.includes(idStep)
                    );
                next = {
                    trigger,
                    values:
                        activity.previewSteps.length && values
                            ? values
                            : valuesCache.default,
                    showDialog: !!hasMoreSteps,
                    showConfirmation: !hasMoreSteps,
                };
            } else {
                next = {
                    trigger,
                    values: valuesCache.triggers[trigger.id],
                    showDialog: true,
                    showConfirmation: false,
                };
            }
        } else {
            next = {
                ...state,
            };
            if (values) next.values = values;
            if (state.showConfirmation) send = true;
            else next.showConfirmation = true;
        }

        if (next.showDialog && !next.trigger.form) {
            next.showDialog = false;
            next.showConfirmation = true;
        }
        if (next.showConfirmation && !next.trigger.confirmation) {
            send = true;
        }

        if (send) {
            try {
                await ActivitiesService.completeForm(
                    ticket.idProject,
                    ticket._id,
                    activity.id,
                    {
                        idTrigger: isDefault ? null : next.trigger.id,
                        values: next.values,
                    }
                );
                dispatch(refreshCurrent(true));
                setState(undefined);
            } catch (error: any) {
                dispatch(refreshCurrent(true));
                VanillaToasts.create({
                    title: 'Error:',
                    text: error.response?.data,
                    type: 'error',
                    timeout: 5000,
                    callback: function () {},
                });
            }
        } else {
            setState(next);
        }
        setLoading(undefined);
    };

    const defaultForm = activity.defaultTrigger.form;

    const renderSubmitBtns = (
        onSubmit?: () => Promise<Record<string, any> | void>
    ): JSX.Element => {
        return (
            <SubmitBtns
                triggers={
                    activity.showTriggers
                        ? activity.triggers.filter(
                              (trigger) =>
                                  !action?.hiddenTriggers?.includes(trigger.id)
                          )
                        : undefined
                }
                idTrigger={loading}
                defaultTrigger={
                    activity.showTriggers ? activity.defaultTrigger : undefined
                }
                onSubmit={onSubmit}
                handleSubmit={handleSend}
                viewFormLabel={activity.viewFormLabel}
            />
        );
    };

    return (
        <React.Fragment>
            {state?.showDialog && (
                <FormDialog
                    onClose={(): void => {
                        setState(undefined);
                    }}
                    label={
                        state.trigger.id === activity.defaultTrigger.id
                            ? activity.name
                            : undefined
                    }
                    customSubmit={async (values): Promise<void> => {
                        await handleSend(state.trigger, values);
                    }}
                    idForm={activity.id + '-' + state.trigger.id}
                    form={state.trigger.form!}
                    values={state.values}
                    rootSteps={state.trigger.form?.rootSteps}
                    sendLabel={state.trigger.form?.label ?? state.trigger.label}
                />
            )}
            {state?.showConfirmation && state.trigger.confirmation && (
                <ConfirmDialog
                    onConfirm={async (
                        _values: any,
                        setLoading: Function
                    ): Promise<void> => {
                        setLoading(true);
                        await handleSend(state.trigger, state.values);
                        setLoading(false);
                    }}
                    deleting={
                        state.trigger.triggerType === ActivityTriggerTypes.ERROR
                    }
                    title={state.trigger.confirmation.title}
                    btnMsg={state.trigger.confirmation.label}
                    msg={state.trigger.confirmation.message}
                    onClose={(): void => {
                        if (state.showDialog) {
                            const newValues = { ...valuesCache };
                            if (
                                state.trigger.id === activity.defaultTrigger.id
                            ) {
                                newValues.default = state.values;
                            } else {
                                newValues.triggers = {
                                    ...(valuesCache.triggers ?? {}),
                                    [state.trigger.id]: state.values,
                                };
                            }
                            setValuesCache(newValues);
                            setState({ ...state, showConfirmation: false });
                        } else {
                            setState(undefined);
                        }
                    }}
                />
            )}
            <InternalActivity title={activity.name}>
                <div className={styles.container}>
                    {activity.helperText && (
                        <label className={styles.helperMessageLbl}>
                            {activity.helperText}
                        </label>
                    )}
                    {defaultForm && activity.previewSteps.length && (
                        <FormRenderer
                            rootSteps={activity.previewSteps}
                            form={defaultForm}
                            values={valuesCache.default}
                            customSubmitBtns={(onSubmit): JSX.Element =>
                                renderSubmitBtns(onSubmit)
                            }
                        />
                    )}
                    {(!defaultForm || !activity.previewSteps.length) &&
                        (activity.showTriggers || activity.viewFormLabel) &&
                        renderSubmitBtns()}
                </div>
            </InternalActivity>
        </React.Fragment>
    );
}

export default FormActivityComponent;
